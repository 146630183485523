@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+Cham:wght@100;200&family=Plus+Jakarta+Sans:wght@200&display=swap);
nothing {
font-family: 'Noto Sans Cham', sans-serif;
font-family: 'Plus Jakarta Sans', sans-serif;
}

.copysymbol {
	cursor: pointer;
}

.sr-only {
	padding-left: 0.125rem;
    padding-right: 0.125rem;
    -webkit-text-stroke-width: 0.5px;
    font-weight: bold;
}

.TourPlanRouteMap {
	margin-bottom: 10px;
}

.HeaderCalendar,
.HeaderSpaces,
.HeaderBlog,
.HeaderTip {
	color: black;
}

.SpacesType a {
	color: white;
	transition: 0.3s ease;
}

.eeDetailFee:hover,
.eeDetailLink:hover,
.eeDetailWebsite:hover,
.eeDetailInsta:hover,
.eeDetailFacebok:hover,
.eeDetailAccess:hover,
.TourPlanRouteMap:hover,
.SubStackLink:hover,
.BlogRead:hover,
.SpacesDetailWebsite:hover,
.SpacesDetailInstagram:hover,
.SpacesDetailFacebook:hover,
.SpacesDetailAccess:hover,
.SpacesDetailCost:hover,
.personLink:hover,
.CalendarEECost:hover {
	color: #89cca3
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #00000000;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #019449;
  }

* {
	box-sizing: border-box;
	scroll-behavior: smooth;
	-webkit-font-smoothing: antialiased;
    text-rendering: geometricPrecision;
	max-width: 100vw;
	font-family: 'Plus Jakarta Sans', sans-serif;
	word-wrap: break-word;
	scrollbar-width: thin;
	scrollbar-color: #019449 #00000000;
}

a,
a:visited {
	text-decoration: none;
}

body {
	margin: 0;
	padding: 0;
	overflow-y: hidden;
}

.MoreIcon {
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.MoreIconLine {
	stroke: black;
	stroke-width: 2px;
}

.BigContainer {
	position: relative;
	height: 100%;
	width: 100vw;
}

.PageContents {
	height: 100%;
	width: 100vw;
}

.header_cont {
	margin-left: auto;
	margin-right: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header_logo {
	align-items: center;
	display:flex;
	flex-wrap: nowrap;
	height: 100%;
	width:auto
}

.header_selection {
	align-items: center;
	display:flex;
	text-decoration: none;
	color: black;
	justify-content: space-around;
}

.HeaderCalendar, .HeaderSpaces, .HeaderBlog, .HeaderCurate, .HeaderTip {
	align-items: center;
	display:flex;
	font-weight: normal;
}

.header_tools {
	align-items: center;
	display:flex;
	margin-right: 5px;
}

.LoadingPageContent {
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
}

.LoadingPage img {
	-webkit-animation: bounce 1s infinite;
	        animation: bounce 1s infinite;
	-webkit-animation-delay: 0.5;
	        animation-delay: 0.5;
}

@-webkit-keyframes bounce {
	0% { -webkit-transform: translateY(0); transform: translateY(0); }
	25% { -webkit-transform: translateY(-30px); transform: translateY(-30px); }
	50% { -webkit-transform: translateY(0); transform: translateY(0); }
	75% { -webkit-transform: translateY(-10px); transform: translateY(-10px); }
	100% { -webkit-transform: translateY(0); transform: translateY(0); }
}

@keyframes bounce {
	0% { -webkit-transform: translateY(0); transform: translateY(0); }
	25% { -webkit-transform: translateY(-30px); transform: translateY(-30px); }
	50% { -webkit-transform: translateY(0); transform: translateY(0); }
	75% { -webkit-transform: translateY(-10px); transform: translateY(-10px); }
	100% { -webkit-transform: translateY(0); transform: translateY(0); }
}

.react-calendar { 
	max-width: 100%;
	background-color: #fff;
	color: #222;
	border-radius: 8px;
	box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2);
	font-family: Arial, Helvetica, sans-serif;
	line-height: 1.125em;
}

.InformationButton:hover .tooltiptext {
	visibility: visible;
}

.react-calendar__navigation button {
	color: #019449;
	min-width: 44px;
	background: none;
	font-size: 20px;
	margin-top: 8px;
	font-weight: bold;
}

.CalendarEEInfo {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: flex-start;
	width: 70%;
	justify-content: space-between;
}

.CalendarListEventName,
.CalendarEventName,
.TourPlanGallery,
.eeDetailSpaceName,
.spaceDetailName,
.CalendarEEAdd button:hover,
.CalendarEEAccessory div:hover,
.eeDetailTopRight button:hover,
.eeDetailTopRight div:hover {
	font-weight: bold;
}

.SpaceKeyTitle {
	margin-left: auto;
	display: flex;
	justify-content: center;
	width: 100%;
}

.MoreNavigationContainer {
	position:absolute;
	top: 0px;
	z-index: 99999;
	background-color: black;
	height: 100vh;
	width: 100vw;
	-webkit-animation: fadeIn 0.3s;
	        animation: fadeIn 0.3s;
}

.CalendarEECost,
.eeDetailWebsite,
.eeDetailInsta,
.eeDetailFacebok,
.eeDetailAccess,
.eeDetailFee,
.SubStackLink,
.CalendarEEWebsite,
.CalendarEEInstagram,
.CalendarEEFacebook,
.eeDetailLink,
.TourPlanRouteMap,
.BlogRead,
.SpacesDetailWebsite,
.SpacesDetailInstagram,
.SpacesDetailFacebook,
.SpacesDetailAccess,
.SpacesDetailCost,
.personLink {
	color: #019449;
	transition: 0.3s ease;
	cursor: pointer;
	font-weight: bold;
}

@-webkit-keyframes fadeIn {
	0% { opacity: 0; }
	100% { opacity: 1; }
  }

@keyframes fadeIn {
	0% { opacity: 0; }
	100% { opacity: 1; }
  }

.CalendarExhibitDates,
.eeDate,
.EECalendarArtist,
.eeDetailTopArtists,
.TourPlanLocation,
.spaceDetailAddress {
	font-style: italic;
}

.CalendarContent {
	height:100%;
	width:100%;
	display: flex;
	flex-direction: column;
}

.CalendarType {
	margin-left:5px;
	align-items: center;
	display:flex;
	flex-wrap: nowrap;
	padding:3px;
	border-radius: 3px;
	border: 1px solid #019449;
	color: white !important;
	background-color: #019449;
	font-weight: bold;
	cursor: pointer;
	transition: 0.3s ease;
}

.TimeFilter {
	margin-left:5px;
	align-items: center;
	display:flex;
	flex-wrap: nowrap;
	border-bottom: 2px solid #019449;
	cursor: pointer;
}

.MoreNavigationContainer {
	position:absolute;
	top: 0px;
	z-index: 99999;
	background-color: black;
	height: 100vh;
	width: 100vw;
}

.MoreCrossCont {
	right: 5px;
	top:10px;
	width: 50px;
	height: 50px;
	position:fixed;
	z-index: 99999;
	cursor: pointer
}

.MoreCross {
	height: 100%;
	width: 100%;
}

.MoreCrossLine {
	stroke:white;
	stroke-width: 2px;
}

.MoreMainItems {
	width:90%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.MoreCalendar,
.MoreHeader,
.MoreBlog,
.MoreCurate,
.MoreTip {
	border-bottom: 1px solid white;
	margin-bottom: 5px;
	font-size: inherit;
	font-weight: bold;
	width: 100%;
}

.react-select-type .react-select-type__control, 
.react-select-type__control .react-select-type__value-container {
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	padding: 0px;
	min-height: 0px;
	cursor: pointer;
}

.MoreSectionsCont {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	color:white;
	position: fixed;
	font-size: 40px;
	padding: 10px;
}

.MoreLesserItems {
	display: flex;
	flex-direction: column;
}

.react-select-time .react-select-time__input-container, 
.react-select-time .react-select-time__single-value, 
.react-select-type .react-select-type__input-container, 
.react-select-type .react-select-type__single-value,
.react-select-vernissage .react-select-vernissage__input-container, 
.react-select-vernissage .react-select-vernissage__single-value,
.react-select-type .react-select-type__input-container, 
.react-select-type .react-select-type__single-value {
	padding-bottom: 0px;
	padding-top: 0px;
	color: black;
	cursor: pointer;
}

.react-select-time .react-select-time__control--is-focused, 
.react-select-type .react-select-type__control--is-focused,
.react-select-vernissage .react-select-vernissage__control--is-focused,
.react-select-type .react-select-type__control--is-focused {
	box-shadow: none;
	cursor: pointer;
}

.react-select-time .react-select-time__indicator-separator,
.react-select-time .react-select-time__option--is-focused,
.react-select-type .react-select-type__indicator-separator,
.react-select-type .react-select-type__option--is-focused,
.react-select-vernissage .react-select-vernissage__indicator-separator,
.react-select-vernissage .react-select-vernissage__option--is-focused,
.react-select-type .react-select-type__indicator-separator,
.react-select-type .react-select-type__option--is-focused,
.react-select-institute .react-select-institute__indicator-separator,
.react-select-institute .react-select-institute__option--is-focused,
.react-select-space .react-select-space__indicator-separator,
	.react-select-space .react-select-space__option--is-focused {
	background-color: transparent;
	cursor: pointer;
}

.react-select-time .react-select-time__indicator, 
.react-select-type .react-select-type__indicator,
.react-select-vernissage .react-select-vernissage__indicator,
.react-select-institute .react-select-institute__indicator,
.CalendarCalendarCont .react-calendar__month-view__weekdays__weekday,
.react-select-space .react-select-space__indicator {
	padding: 0px;
	cursor: pointer;
}

.react-select-time .react-select-time__menu,
.react-select-type .react-select-type__menu,
.react-select-vernissage .react-select-vernissage__menu {
	border-radius: 0px;
	margin-top: 3px;
	margin-bottom: 0px;
	cursor: pointer;
}

.react-select-time .react-select-time__menu-list div:hover,
.react-select-type .react-select-type__menu-list div:hover,
.react-select-institute .react-select-institute__menu-list div:hover,
.react-select-vernissage .react-select-vernissage__menu-list div:hover,
.react-select-vernissage .react-select-vernissage__menu-list div:hover,
.CalendarType:hover,
.TourPlan:hover,
.SpacesApppear:hover,
.SpacesType:hover,
.react-select-space .react-select-space__menu-list div:hover {
	background-color: #89cca3;
	cursor: pointer;
}

.react-select-time .react-select-time__option--is-selected,
.react-select-type .react-select-type__option--is-selected,
.react-select-institute .react-select-institute__option--is-selected,
.react-select-vernissage .react-select-vernissage__option--is-selected,
.react-select-space .react-select-space__option--is-selected {
	background-color: #019449;
	cursor: pointer;
}

.react-calendar__tile--now,
.CalendarEEWebsite:hover,
.CalendarEEInstagram:hover,
.CalendarEEFacebook:hover {
	background-color:#89cca3 !important;
}

.react-calendar__tile--now > * {
	color: white;
	font-weight: bold;
}

.CalendarCalendarItself {
	margin-left: auto;
	margin-right: auto;
}

.highlight abbr {
	height:100%;
	width: 70%;
	border-radius: 100px;
	background-color: #89cca3;
	display: inline-block;
}

.CalendarExhibitiSectionCont, 
.CalendarEventSectionCont {
	margin-top: 5px;
	display: flex;
	flex-direction: column;
}

.CalendarEventTitle,
.CalendarExhibitiTitle {
	text-align: center;
	font-weight: bold;
}

.CalendarEECont {
	margin-top: 5px;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	padding-left:3px;
	padding-right:3px;
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid black;
	padding-bottom: 5px;
}

.CalendarEESummary {
	display: flex;
	flex-direction: row;
	height: 100%;
}

.CalendarEEFee,
.EventDescCont {
	margin-top: 5px;
	text-align: justify;
}

.CalendarEEAccessory {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: flex-end;
	width: 30%;
}

.CalendarEEAdd, 
.CalendarEETourAdd, 
.CalendarStickerNew,
.CalendarStickerArchive,
.CalendarStickerFinal,
.CalendarEERead {
	display:flex;
	position:relative;
}

.CalendarEETourAdd {
	border: 1px solid #019449;
	padding:2px;
	border-radius: 3px;
}

.CalendarStickerNew,
.CalendarStickerArchive,
.CalendarStickerFinal {
	padding:2px;
	font-weight: bold;
}

.CalendarStickerNew {
	color: #019449;
	font-style: italic;
}

.CalendarStickerFinal {
	color: red;
	font-style: italic;
}

.CalendarStickerArchive {
	color: grey;
	font-style: italic;
	cursor: default !important
}

.CalendarEEReadMore,
.CalendarEEReadMoreList,
.CurrentlyShowingTitle,
.PreviouslyShownTitle {
	text-decoration: underline;
	color: black;
}

.CalendarEEReadLess,
.CalendarEEReadLessList {
	display: none;
	text-decoration: underline;
}

.CalendarEEExtraContent {
	display: flex;
	flex-direction: column;
}

.CalendarEESpaceExtra {
	display: flex;
	flex-direction: row;
	margin-top: 10px;
	padding-left : 4px;
	padding-right: 4px;
	border-top: 1px solid black;
	border-bottom: 1px solid black;
}

.CalendarEESpaceTime {
	width: 50%;
	display: flex;
	flex-direction: column;
	border-right: 1px solid black;
}

.CalendarEESpaceSocials {
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-around
}

.CalendarListEEAddress,
.SpacesDetailCaption {
	text-align: center;
}

.SpacesContent {
	height:100%;
	width:100%;
	display: flex;
	flex-direction: column;
}

.SpacesFilterBar {
	width: 98vw;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.SpacesTypeContent {
	display: flex;
	flex-direction: column;
	overflow-y: visible;
	padding-bottom: 50px;
}

.SpacesSort {
	width: 100%;
	margin-left: 5px;
	margin-right: 5px;
	text-align: center;
	align-content: center;
	justify-content: center;
}

.SpacesSortList {
	align-items: center;
	display:flex;
	flex-wrap: nowrap;
	padding:3px;
	border-radius: 3px;
	flex-direction: row;
	justify-content: space-between;
}

.SpacesTypeSelect {
	border-bottom: 2px solid #019449;
}

.AlphabetJump {
	display: flex;
	text-decoration: underline;
	flex-direction: row;
	color: black;
}

.SpacesSortMap {
	border-bottom: 2px solid #019449;
	margin-left: auto;
	margin-right: auto;
}

.react-select-institute .react-select-institute__control, 
.react-select-institute__control .react-select-institute__value-container {
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	padding: 0px;
	min-height: 0px;
}

.react-select-institute .react-select-institute__input-container, 
.react-select-institute .react-select-institute__single-value {
	padding-bottom: 0px;
	padding-top: 0px;
	color: black;
}

.react-select-institute .react-select-institute__control--is-focused {
	box-shadow: none;
}

.react-select-institute .react-select-institute__menu {
	border-radius: 0px;
	margin-top: 3px;
	margin-bottom: 0px;
}

.react-select-institute .react-select-institute__control {
	background-color: transparent;
	border-color: transparent;
	border-style: none;
}

.SpacesApppear,
.SpacesType {
	align-items: center;
	display:flex;
	flex-wrap: nowrap;
	padding: 2px;
	border-radius: 3px;
	border: 1px solid #019449; 
	color: white;
	background-color: #019449;
	font-weight: bold;
	cursor: pointer;
	transition: 0.3s ease;
}

.react-select-type .react-select-type__control {
	cursor: pointer;
	background-color: transparent;
	border-color: transparent;
	border-style: none;
}

.TypeFilter {
	margin-left:5px;
	align-items: center;
	display:flex;
	border-bottom: 2px solid #019449;
	flex-wrap: nowrap;
}

.TourPlan {
	margin-right:5px;
	align-items: center;
	display:flex;
	flex-wrap: nowrap;
	padding:2px;
	border-radius: 3px;
	border: 1px solid #019449;
	color: white !important;
	background-color: #019449;
	font-weight: bold;
	transition: 0.3s ease;
	cursor: pointer;
}

.CalendarTypeContent {
	display: flex;
	height: 100%;
	flex-direction: column;
}

.CalendarCalendarContent {
	width:100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.CalendarsDate {
	display:flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
}

.CalendarCalendarCont {
	width: 100%;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	position: relative;
	border-bottom: 1px solid black;
}

.CalendarCalendarCont .react-calendar {
	background-color: transparent;
	width: 100%;
	border: none;
	position: relative;
	height: 100%;
}

.CalendarCalendarCont .react-calendar__navigation {
	height: 10%;
	margin-bottom: 0px;
	position: relative;
}

.react-calendar__month-view__days .react-calendar__month-view__days__day--weekend {
	color:black !important
}

.CalendarCalendarCont .react-calendar__tile--active:enabled:focus,
.CalendarCalendarCont .react-calendar__tile--active  {
	background-color: #019449 !important
}

.SpacesCalendarList {
	height: 100%;
}

.SpaceContainer {
	width: 100%;
	height:100%;
	padding: 5px;
	border-bottom: 1px solid black;
}

.SpaceContents {
	width: 100%;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	display: flex;
	flex-direction: column;
}

.SpaceGalleryTypeCont {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.SpaceName {
	margin-left: 5px;
	font-weight: bold;
}

.SpaceType {
	margin-right: 5px;
}

.SpaceAddressReadCont {
	margin-top: 5px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.SpaceAddress {
	margin-left: 5px;
	font-style: italic;
	width: 70%;
}

.SpaceReadMore {
	margin-right: 5px;
	display: flex;
	text-decoration: underline;
	align-items: flex-end;
	cursor: pointer;
	color: black;
}

.SpaceReadLess {
	margin-right: 5px;
	display: none;
	text-decoration: underline;
	align-items: flex-end;
}

.SpacesDetailExtraContent {
	width: 100%;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	flex-direction: column;
	display: flex;
}

.SpacesCurrenlyShowing,
.SpacesPreviouslyShown {
	width: 100%;
	text-align: center;
	margin-top: 10px;
	margin-bottom: 10px;
}

.SpacesDetailCommLinks {
	display: flex;
	width: 100%;
	justify-content: space-between;
	flex-direction: column;
	margin-top: 10px;
}

.SpacesDetailPhoneEmail {
	display: flex;
	justify-content: space-evenly;
    align-items: center;
	flex-direction: row;
}

.SpacesDetailLinks {
	display: flex;
	justify-content: space-around;
	flex-direction: row;
	align-items: center;
	margin-top: 10px;
	width: 80%;
	margin-left: auto;
	margin-right: auto;
}

.SpacesDetailDescriptionImage {
	margin-top: 10px;
	display:flex;
	flex-direction: row;
	text-align: center;
	align-items: center;
}

.SpacesDetailAbout {
	display: flex;
	text-align: justify;
}

.SpacesDetailImgCaption {
	width: 49%;
	display: flex;
	flex-direction: column;
	height: auto;
}

.SpacesDetailImg {
	display: flex;
	width: 100%;
	height: 100%;	
}

.tourplanMapCont {
	height: 400px;
	width: 100%;
}

.CalendarEEAdd button,
.CalendarEEAccessory div {
	cursor: pointer;
}

.SpaceLetterSection {
	width:100%;
	justify-content: center;
	height:-webkit-fit-content;
	height:-moz-fit-content;
	height:fit-content;
	display: flex;
	flex-direction: column;
}

.SpaceKeycontent {
	width:100%;
	padding: 10px;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	display: flex;
	flex-direction: column;
}

.SpacesDetailEmail {
	display: flex;
	flex-direction: row;
}

.BlogEntryCont {
	width: 100%;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	display: flex;
	flex-direction: column;
}

.BlogTitle {
	width: 100%;
	flex-wrap: auto;
	font-weight: bold;
}

.BlogAuthor {
	width: 100%;
	flex-wrap: auto;
	font-style:italic;
}

.BlogAuthor {
	width: 100%;
	flex-wrap: auto;
}

.BlogContent {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.BlogFilterBar {
	flex: 1 1;
	height: 40px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
}

.SubStackLink {
	justify-content: flex-start;
	display: flex;
	align-items: center;
	font-weight: bold;
}

.BlogEntriesCont {
	flex: 2 1 auto;
	width: 100%;
	height: calc(100vh - 80px);
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	padding-bottom: 50px
}

.BlogEntryContent {
	padding-bottom: 5px;
	padding-top: 5px;
	width: 95%;
	margin-left: auto;
	margin-right: auto;
	border-bottom: 1px solid black;
	display: flex;
	flex-direction: column;
}

.timerCont {
	width: 100%;
	margin-top: 10px;
}

progress {
	width: 100%;
}

/* About Page */
.AboutPageCont {
	width:100%;
	height:100vh;
	display: flex;
	flex-direction: column;
	justify-content: left;
	overflow-y: auto;
	padding-bottom: 200px;
}

.AboutPageText {
	width:calc(100% - 20px);
	margin-left: auto;
	margin-right: auto;
	height:-webkit-fit-content;
	height:-moz-fit-content;
	height:fit-content;
	text-align: justify;
}

.SeaslugEmail {
	width:calc(100% - 20px);
	margin-left: auto;
	margin-right: auto;
	height:-webkit-fit-content;
	height:-moz-fit-content;
	height:fit-content;
	text-align: left;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: left;
}

.SeaslugEmail .EmailText {
	display: flex;
	align-items: center;
}

.SeaslugEmail .EmailCopySymbol {
	margin-left:5px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: left;
}

.AboutPageTeam {
	width:calc(100% - 20px);
	height:-webkit-fit-content;
	height:-moz-fit-content;
	height:fit-content;
	margin-left: auto;
	margin-right: auto;
	text-align: left;
	padding-bottom: 50px;
}

.aboutImages {
	display: flex;
	flex-direction: row;
	justify-content: center;
	grid-gap: 20px;
	gap: 20px;
	flex-wrap: wrap;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
}

.personLink {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.personLink:hover > .memberPicture {
	-webkit-filter:opacity(0.9);
	        filter:opacity(0.9)
}

.memberPicture {
	width: 300px;
	height: auto;
	transition: 0.3s ease;
}

.memberRole {
	text-align: center;
	font-size: 20px;
}

.ExhibitVernisageTime {
	display:flex;
	flex-direction: row;
	margin-top: 5px;
	width: 100%;
	justify-content: flex-start;
}

.VernisageCheckDropdown {
	margin-left: auto;
	width: 30%;
	border-bottom: 2px solid #019449;
}

.react-select-space .react-select-space__menu,
.react-select-type .react-select-type__menu,
.react-select-space .react-select-space__menu,
.react-select-type .react-select-type__menu {
	border-radius: 0px;
	margin-top: 3px;
	margin-bottom: 0px;
}

.BlogDate {
	display: flex;
	justify-content: flex-start;
}

.react-select-space .react-select-space__input-container, 
.react-select-space .react-select-space__single-value {
	padding-bottom: 0px;
	padding-top: 0px;
	color: black;
	cursor: pointer;
}

.react-select-vernissage .react-select-vernissage__control, 
.react-select-vernissage__control .react-select-vernissage__value-container {
	background-color: transparent;
	border-color: transparent;
	border-style: none;
	padding: 0px;
	min-height: 0px;
	cursor: pointer;
}

.react-select-space .react-select-space__control--is-focused {
	box-shadow: none;
}

.VernisageTimeCalendar {
	margin-left: 5px;
}

.DatesEvent div.react-datepicker__input-container input {
	display: block;
	width: 100% !important;
	text-align: center;
	cursor: pointer;
}

.spaceDetailCont {
	width: 100%;
}

@media screen and (max-width: 3000px) {
	.sr-only {
		font-size: 12px;
	}

	*,
	.SpacesDetailAccess {
		font-size: 20px;
	}

	.header_cont {
		height:80px;
		width:98vw;
	}

	.header_logo {
		margin-left:10px;
	}

	.webLogo {
		width: auto;
		height: 60px
	}

	.header_selection {
		width: 600px;
	}

	.header_insta_cont {
		margin-right: 20px;
		cursor: pointer;
	}

	.instaLogo {
		width: 50px;
		height: 50px;
	}

	.MoreIconCont {
		width: 50px;
		height: 50px;
	}

	.LoadingPageContent {
		height: calc(100vh - 80px);
	}

	.InformationButton {
		display: flex;
		border-radius: 200px;
		background-color: #019449;
		color:white;
		height: 18px;
		width: 18px;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-weight: bold;
		cursor: default;
		margin-left:5px;
	}

	.InformationButton .tooltiptext {
		visibility: hidden;
		width: 300px;
		background-color: rgba(255, 255, 255, 0.95);
		color: black;
		text-align: left;
		border-radius: 3px;
		padding: 2px;
		border: 2px solid #89cca3;
	
		/* Position the tooltip */
		position: absolute;
		z-index: 1;
		margin-left:10%;
	}


	/* More Navigation */
	.MoreSectionsCont {
		text-decoration: underline;
	}

	.MoreMainItems a,
	.MoreMainItems div {
		font-size: 50px;
		color: white;
	}

	.MoreLesserItems > * {
		font-size: 16px;
	}

	/* Calendar page */
	.SpacesCalendarContent {
		display: flex;
		flex-direction: row;
		height: calc(100vh - 140px);
		width: 100%;
	}

	.CalendarFilterBar {
		width: 100vw;
		padding-left: 35px;
		padding-right: 35px;
		height: 60px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-left: auto;
		margin-right: auto;
	}

	.react-select-time .react-select-time__input-container, 
	.react-select-time .react-select-time__single-value, 
	.react-select-type .react-select-type__input-container, 
	.react-select-type .react-select-type__single-value,
	.CalendarType,
	.TourPlan,
	.CalendarEventTitle,
	.CalendarExhibitiTitle,
	.CalendarCalendarCont .react-calendar__navigation__label__labelText,
	.CalendarCalendarCont .react-calendar__navigation__arrow,
	.react-calendar__month-view__weekdays__weekday abbr,
	.react-calendar__tile abbr,
	.SpaceAddressReadCont,
	.eeSpaceName,
	.SpacesDetailWebsite,
	.SpacesDetailInstagram,
	.SpacesDetailFacebook,
	.HeaderCalendar,
	.HeaderSpaces,
	.HeaderBlog,
	.HeaderCurate,
	.HeaderTip,
	.SpacesType a,
	.AlphabetJump,
	.react-select-institute .react-select-institute__input-container, 
	.react-select-institute .react-select-institute__single-value,
	.SpacesApppear div,
	.SpaceKeyTitle,
	.SubStackLink,
	.spacePlaceholdler {
		font-size: 26px;
	}

	.spacePlaceholdler {
		height: 70%;
		width: 70%;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		align-items: center;
		font-weight: bold;
	}

	.calendarOrganise {
		display: flex;
		flex-direction: row;
	}

	.CalendarTypeContent {
		width: 50%;
		padding-left: 35px;
	}

	.CalendarsDate {
		height: 30px;
		font-size: 26px;
	}

	.react-calendar__tile {
		line-height: 12px !important;
	}

	.CalendarCalendarList {
		flex: 2 1 auto;
		width: 100%;
		height: calc(100vh - 426px);
		overflow-y: auto;
		padding-left: 5px;
		padding-right: 5px;
		padding-bottom: 50px
	}

	.CalendarListContent {
		flex: 2 1 auto;
		width: 100%;	height: calc(100vh - 140px);
		overflow-y: auto;
		padding-right: 5px;
		display: flex;
		flex-direction: column;
		padding-bottom: 50px
	}

	.EventDescLink {
		border: 1px solid #019449;
		background-color: #019449;
		padding:2px;
		border-radius: 3px;
	}

	.calendarDetailContent {
		width: 50%;
		height: calc(100vh - 140px);
		display: flex;
		padding-right: 35px;
		padding-left: 35px;
		overflow-y: auto;
		overflow-x: hidden;
		padding-bottom: 50px
	}

	.eeDetailContent {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.eeDetailTop {
		display:flex;
		flex-direction: row;
	}

	.eeDetailTopLeft,
	.eeDetailTopRight {
		display:flex;
		flex-direction: column;
		flex-wrap: wrap;
	}

	.eeDetailTopRight {
		align-items: flex-end;
	}

	.eeDetailTopLeft {
		align-content: flex-start;
		width: 75%;
	}

	.eeDetailTopRight {
		align-content: flex-end;
		width: 25%;
	}

	.eeSpaceNameType {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: flex-start;;
	}

	.eeSpaceExtraType {
		display: flex;
		width: 100%;
		flex-direction: row;
		justify-content: space-around
	}

	.eeDetailSpacePlace,
	.eeDetailSpacePhone,
	.eeDetailSpaceEmail {
		width: 100%;
		text-align: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	.eeDetailDescription {
		margin-top: 10px;
		text-align: justify;
	}

	.eeDetailSpaceInfo {
		width: 95%;
		display: flex;
		flex-direction: row;
		border-top: 1px solid black;
		border-bottom: 1px solid black;
		padding-left: 10px;
		padding-right: 10px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 10px;
	}

	.eeDetailSpaceInfoLeft{
		padding: 5px;
		display: flex;
		flex-direction: column;
		width: 50%;
		border-right: 1px solid black;
	}

	.eeDetailSpaceInfoRight {
		padding: 5px;
		display: flex;
		flex-direction: column;
		width: 50%;
	}

	.eeDetailSpaceExternal,
	.eeDetailSpaceExternal2 {
		display: flex;
		flex-direction: row;
		width: 100%;
		margin-top: 5px;
		justify-content: space-evenly;
		flex-wrap: wrap;
	}

	.eeDetailMapsCont {
		width: 95%;
		margin-left: auto;
		margin-right: auto;
		height: 500px;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	/* Spaces Page */

	.SpacesFilterBar {
		height: 60px;
	}

	.react-select-institute .react-select-institute__control,
	.SpacesSortMap {
		width: 250px;
	}

	.SpacesTypeContent {
		height: calc(100vh - 140px);
		width: 100%;
	}

	.SpacesCalendarCont {
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 100%;
		overflow-y: auto;
		padding-bottom: 50px
	}

	.SpacesCalendarListCont {
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 100%;
		padding: 10px;
		overflow-y: auto;
	}

	.SpacesListContent {
		height: calc(100vh - 140px);
		width: 50%;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		padding-bottom: 50px
	}

	.SpacesPreviouslyShown {
		padding-bottom: 10px;
	}

	.spaceDetailExtra {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 95%;
		margin-left: auto;
		margin-right: auto;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.spaceDetailExtraRight,
	.spaceDetailExtraLeft {
		width: 50%;
		display: flex;
		flex-direction: column;
		padding-top: 5px;
		padding-bottom: 5px;
	}

	.spaceDetailExtraRight {
		align-items: center;
	}

	.SpacesDetailTimeExtra {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		margin-top: 10px;
	}

	.SpacesDetailTime {
		width: 50%;
		display: flex;
		flex-direction: column;
	}

	.SpacesDetailExtra {
		width: 50%;
		display: flex;
		flex-direction: column;
		text-align: center;
		align-items: flex-end;
		justify-content: space-around;
	}

	.SpacesDetailMapsCont {
		width: 100%;
		height: 400px;
		margin-top: 10px;
		padding-bottom: 10px;
	}

	.spaceDetailContent {
		display: flex;
		width: 50%;
		height: calc(100vh - 140px);
		overflow-y: auto;
		padding-right: 10px;
		padding-left: 10px;
		overflow-x: hidden;
		padding-bottom: 50px;
		padding-top: 10px;
	}

	.spaceDetailTop {
		display: flex;
		flex-direction: row;
		justify-content: space-between
	}

	.spaceDetailContacts {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
	}

	.spaceDetailSocials {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
	}

	/* Blog Page */
	.BlogFilterBar {
		height: 60px;
	}

	.SubStackRss {
		justify-content: flex-start;
		margin-left: 5px;
		display: flex;
		align-items: center;
		color: #019449 !important;
	}

	.BlogDescription {
		margin-top: 5px;
		text-align: justify;
	}

	.DateLinkCont {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: space-between;
		margin-top: 10px;
	}

	/* Tip Us Page */
	.TipUsContent {
		height: calc(100vh - 80px);
		width: 100%;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		padding-bottom: 50px
	}

	.TipUsDescription {
		padding-left: 20px;
		padding-right: 20px;
		text-align: center;
		padding-top: 10px;
	}

	.TipUsRadio {
		width: 100%;
		text-align: center;
		padding-top: 10px;
	}

	.TipUsForm {
		width: 100%;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	#Form {
		padding-top: 10px;
		display: flex;
		flex-direction: column;
		padding-left: 100px;
		padding-right: 100px;
	}

	.NameCont {
		display: flex;
		flex-direction: row;
		width:100%;
	}

	.NameLabel,
	.SpaceLabel,
	.ArtistLabel,
	.TypeLabel,
	.LinkLabel,
	.CuratorLabel,
	.ContactLabel,
	.ImageLabel,
	.CaptionLabel,
	.AdmissionLabe,
	.AdmissionCostLabel {
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		float: left;
		display: flex;
		flex-direction: row;
	}

	.NameContent {
		width:65%;
		margin-left: auto;
	}

	.SpaceCont {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: center;
		align-items: center;
		margin-top: 5px;
	}

	.SpaceDropdown {
		margin-left: auto;
		width: 80%;
		border-bottom: 2px solid #019449;
	}

	.react-select-space .react-select-space__control, 
	.react-select-space__control .react-select-space__value-container {
		background-color: transparent;
		border-color: transparent;
		border-style: none;
		padding: 0px;
		min-height: 0px;
	}

	.react-select-space .react-select-space__control {
		background-color: transparent;
		border-color: transparent;
		border-style: none;
	}

	.ArtistCont {
		display: flex;
		flex-direction: row;
		width:100%;
		margin-top: 5px;
	}

	.ArtistContent {
		width:70%;
		margin-left: auto;
	}

	.TypeCont {
		display: flex;
		flex-direction: row;
		width:100%;
		justify-content: flex-start;
		align-items: center;
		margin-top: 5px;
	}

	.TypeDropdown {
		width: 200px;
		margin-left: 10px;
		border-bottom: 2px solid #019449
	}

	.react-select-time .react-select-time__control, 
	.react-select-time__control .react-select-time__value-container,
	.react-select-type .react-select-type__control, 
	.react-select-type__control .react-select-type__value-container {
		background-color: transparent;
		border-color: transparent;
		border-style: none;
		padding: 0px;
		min-height: 0px;
		width: 200px;
	}

	.ArtistContent {
		width:70%;
		margin-left: auto;
	}

	.DatesExhibit {
		display:flex;
		flex-direction: row;
		width: 800px;
		margin-top: 5px;
		justify-content: center;
		align-items: center;
		justify-content: flex-start;
	}

	.ExhibitStartDateCont {
		display: flex;
		flex-direction: row;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
	}

	.ExhibitStartDateLabel {
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
	}

	.ExhibitStartCalendar {
		margin-left: 10px;
	}

	.DatesExhibit div.react-datepicker__input-container input,
	.ExhibitVernisageTime div.react-datepicker__input-container input {
		display: block;
		width: 100% !important;
		text-align: center;
		cursor: pointer;
	}

	.ExhibitEndDateCont {
		display: flex;
		flex-direction: row;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		margin-left: 5px;
	}

	.ExhibitEndDateLabel {
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
	}

	.ExhibitEndCalendar {
		margin-left: 10px;
	}

	.DatesEvent {
		display:flex;
		flex-direction: column;
		width: 100%;
		margin-top: 5px;
		justify-content: center;
		align-items: center;
	}

	.EventDateMainCont {
		display: flex;
		flex-direction: row;
		width: 100%;
	}

	.EventDateMainLabel {
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
	}

	.EventDateMainCalendar {
		margin-right:20px;
		margin-left: 5px;
	}

	.EventDateMainAdd, .EventDateExtraSubtract {
		display: flex;
		border-radius: 200px;
		font-size: 20px;
		height: 20px;
		width: 20px;
		color: #019449;
		justify-content: center;
		align-items: center;
		font-weight: bold;
		cursor: pointer;
	}

	.EventDateExtraCont {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-top:5px;
	}

	.EventDateExtra {
		display: flex;
		flex-direction: row;
		width: 100%;
	}

	.EventDateLabel {
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
	}

	.EventDateExtraCalendar {
		margin-right: 20px;
		margin-left: 5px;
	}

	.VernissageStuffCont {
		display:flex;
		flex-direction: column;
		width: 100%;
		justify-content: center;
		margin-top: 5px;
	}

	.VernissageCheckCont {
		display:flex;
		flex-direction: row;
		width: 400px;
		justify-content: center;
		align-items: center;
		margin-top: 5px;
	}

	.VernisageCheckLabel {
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		display: flex;
		flex-direction: row;
	}

	.react-select-vernissage .react-select-vernissage__control,
	.react-select-space .react-select-space__control {
		width: 100%;
	}

	.react-select-vernissage .react-select-vernissage__control {
		background-color: transparent;
		border-color: transparent;
		border-style: none;
	}

	.DescriptionCont {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-top: 5px;
	}

	.DescriptionLabel {
		width: 100%;
		text-align: left;
	}

	.DescriptionContent {
		width: 100%;
		resize: none;
	}

	.LinkCont {
		display: flex;
		flex-direction: row;
		width:100%;
		margin-top: 5px;
	}

	.LinkContent,
	.CuratorContent,
	.ContactContent,
	.ImageContent,
	.CaptionContent,
	.AdmissionContent,
	.AdmissionCostContent  {
		width:70%;
		margin-left: auto;
	}

	.CuratorCont {
		display: flex;
		flex-direction: row;
		width:100%;
		margin-top: 5px;
	}

	.ContactCont {
		display: flex;
		flex-direction: row;
		width:100%;
		margin-top: 5px;
	}

	.ImageCont {
		display: flex;
		flex-direction: row;
		width:100%;
		margin-top: 5px;
	}

	.CaptionCont {
		display: flex;
		flex-direction: row;
		width:100%;
		margin-top: 5px;
	}

	.AdmissionCont {
		display: flex;
		flex-direction: column;
		width:100%;
		margin-top: 5px;
	}

	.AdmissionDropDownCont {
		display: flex;
		flex-direction: row;
	}

	.AdimssionDropDown {
		margin-left: auto;
		width: 70%;
		border-bottom: 2px solid #019449;
	}

	.AdmmissionCostCont {
		display: flex;
		flex-direction: row;
		margin-top: 5px;
	}

	.FormButtonCont {
		margin-top: 10px;
		width: 100%;
		text-align: center;
	}

	.FormButtonCont > div > div {
		display: flex;
		place-content: center;
	} 

	.ExhibitSubmit,
	.EventSubmit {
		margin-left: auto;
		margin-right: auto;
		width:-webkit-fit-content;
		width:-moz-fit-content;
		width:fit-content;
		border:1px solid #019449;
		text-align: center;
		padding: 2px;
		background-color: #019449;
		cursor:pointer;
		color: white;
		font-weight: bold;
	}

	/* Terms Page */

	.TermsPageCont {
		width:100%;
		display: flex;
		flex-direction: column;
		justify-content: left;
		height: calc(100vh - 40px);
		overflow-y: auto;
		overflow-x: hidden;
		padding-bottom: 50px
	}

	.TermsContent {
		width: 100%;
		padding-left: 100px;
		padding-right: 100px;
		margin-left: auto;
		margin-right: auto;
		height:-webkit-fit-content;
		height:-moz-fit-content;
		height:fit-content;
		text-align: left;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
	}

	/* Tourplan page */
	.TourPlanCont {
		display:flex;
		height: calc(100vh - 80px);
		width: 100%;
		flex-direction: column;
		padding: 10px;
		overflow-y: auto;
		padding-bottom: 50px
	}

	.TourPlanEntryCont {
		border: 1px solid #019449;
		display: flex;
		border-radius: 2px;
		flex-direction: row;
		margin-bottom: 10px;
		padding: 2px;
	}

	.TourPlanLeft,
	.TourPlanRight {
		display:flex;
		flex-direction: column;
		width: 50%;
	}

	.TourPlanNothing {
		font-size: 20px;
		margin-top: 20px;
	}

	.TourPlanRouteMap {
		font-size: 20px;
		margin-left: auto;
		margin-right: auto;
	}

	/* Maps Attributtes */
	.MapPin {
		width: 15px;
		height: 15px;
		border-radius: 100%;
		text-align: center;
		cursor: pointer;
	}

	.PinDetail {
		width: 15px;
		height: 15px;
		border-radius: 100%;
		text-align: center;
		background-color: green;
	}

	.MapPinTitle {
		text-align: center;
		justify-content: center;
		display: none;
		font-size: 20px;
		text-decoration: underline;
		padding-top: 15px;
	}

	.PinDetailTitle {
		text-align: center;
		justify-content: center;
		font-size: 20px;
		text-decoration: underline;
		padding-top:15px;
		word-wrap: normal;
		color: #019449;
		font-weight: bold;
	}

	.PinDetailTitle > .CalendarEventName {
		word-wrap: normal;
		color: #019449;
		font-weight: bold;
	}
}


@media screen and (max-width: 720px) {
	*,
	.SpacesDetailPhone,
	.SpaceName,
	.SpaceType,
	.SpaceAddressReadCont,
	.SpaceAddress,
	.SpacesDetailWebsite,
	.SpacesDetailInstagram,
	.SpacesDetailFacebook,
	.SpacesDetailAccess {
		font-size: 14px;
	}

	.header_cont {
		height:40px;
		width:100vw;
	}

	.header_logo {
		margin-left:5px;
	}

	.webLogo {
		width: auto;
		height: 30px;
	}

	.header_selection {
		width: auto;
	}

	.HeaderCalendar,
	.HeaderSpaces,
	.HeaderBlog,
	.HeaderCurate,
	.HeaderTip,
	.CalendarEventTitle,
	.CalendarExhibitiTitle,
	.SpaceKeyTitle,
	.SubStackLink {
		font-size: 20px;
	}

	.header_insta_cont {
		margin-right: 10px;
		cursor: pointer;
	}
	
	.instaLogo {
		width: 20px;
		height: 20px;
	}

	.MoreIconCont {
		width: 20px;
		height: 20px;
	}

	.LoadingPageContent {
		height: calc(100vh - 40px);
	}

	.InformationButton {
		display: flex;
		border-radius: 200px;
		background-color: #019449;
		color:white;
		height: 18px;
		width: 18px;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-weight: bold;
		cursor: default;
		margin-left:5px;
	}

	.InformationButton .tooltiptext {
		visibility: hidden;
		width: 300px;
		background-color: rgba(255, 255, 255, 0.95);
		color: black;
		text-align: left;
		border-radius: 3px;
		padding: 2px;
		border: 2px solid #89cca3;
	
		/* Position the tooltip */
		position: absolute;
		z-index: 1;
	}

	/* More Navigation */
	.MoreCrossCont {
		width: 30px;
		height: 30px;
		position:fixed;
		z-index: 99999;
		cursor: pointer;
	}

	.MoreSectionsCont {
		text-decoration: none;
	}

	.MoreMainItems {
		width:90%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.MoreMainItems a,
	.MoreMainItems div {
		font-size: 26px;
	}

	.MoreLesserItems > * {
		font-size: 16px;
		color: white;
	}

	/* Calendar page */
	.SpacesCalendarContent {
		display: flex;
		flex-direction: column;
		height: calc(100vh - 80px);
		width: 100%;
	}

	.CalendarFilterBar {
		width: 100vw;
		padding-left: 0px;
		padding-right: 0px;
		height: 40px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.CalendarType,
	.SpaceReadMore,
	.TourPlan,
	.react-select-time .react-select-time__input-container, 
	.react-select-time .react-select-time__single-value, 
	.react-select-type .react-select-type__input-container, 
	.react-select-type .react-select-type__single-value,
	.CalendarCalendarCont .react-calendar__navigation__label__labelText,
	.CalendarCalendarCont .react-calendar__navigation__arrow,
	.react-calendar__month-view__weekdays__weekday abbr,
	.react-calendar__tile abbr,
	.SpacesType a,
	.AlphabetJump,
	.SpacesApppear div,
	.react-select-institute .react-select-institute__input-container, 
	.react-select-institute .react-select-institute__single-value,
	.SpacesDetailEmail {
		font-size: 14px;
	}

	.CalendarTypeContent,
	.react-select-type .react-select-type__control {
		width: 100%;
		padding-left: 0px;
	}

	.CalendarsDate {
		height: 20px;
		font-size: 14px;
	}

	.react-calendar__tile {
		line-height: 6px !important;
	}

	.CalendarCalendarList {
		flex: 2 1 auto;
		width: 100%;
		height: calc(100vh - 288px);
		overflow-y: auto;
		padding-left: 5px;
		padding-right: 5px;
		padding-bottom: 50px
	}

	.CalendarListContent {
		flex: 2 1 auto;
		width: 100%;	height: calc(100vh - 80px);
		overflow-y: auto;
		padding-left: 5px;
		padding-right: 5px;
		display: flex;
		flex-direction: column;
		padding-bottom: 50px
	}

	.EventDescLink {
		border: 1px solid #019449;
		background-color: #019449;
		padding:2px;
		border-radius: 3px;
	}

	/* Spaces Page */
	.SpacesFilterBar {
		height: 40px;
	}

	.SpacesSortMap,
	.react-select-institute .react-select-institute__control {
		width: 150px;
	}

	.SpacesTypeContent {
		height: calc(100vh - 80px);
		width: 100%;
	}

	.SpacesCalendarCont {
		display: flex;
		flex-direction: column;
		height: 50%;
		width: 100%;
		overflow-y: auto;
		padding-bottom: 0px
	}

	.SpacesCalendarListCont {
		display: flex;
		flex-direction: column;
		height: calc(100vh - 50% - 80px);
		width: 100%;
		padding: 10px;
		overflow-y: auto;
		padding-bottom: 50px
	}

	.SpacesListContent {
		height: calc(100vh - 80px);
		width: 100%;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		padding-bottom: 50px
	}

	.SpacesDetailTimeExtra {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		margin-top: 10px;
		border-top: 1px solid black;
		border-bottom: 1px solid black;
	}

	.SpacesDetailTime {
		width: 49%;
		display: flex;
		flex-direction: column;
		border-right: 1px solid black;
	}

	.SpacesDetailExtra {
		width: 49%;
		display: flex;
		flex-direction: column;
		text-align: center;
		align-items: flex-end;
		justify-content: space-around;
	}

	.SpacesDetailMapsCont {
		width: 100%;
		height: 200px;
		margin-top: 10px;
		padding-bottom: 10px;
	}

	/* Blog Page */
	.BlogFilterBar {
		height: 40px;
	}

	.SubStackRss {
		justify-content: flex-start;
		margin-left: 5px;
		display: flex;
		align-items: center;
		color: #019449 !important;
	}

	.DateLinkCont {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: space-between
	}

	/* Tip Us Page */
	.TipUsContent {
		height: calc(100vh - 40px);
		width: 100%;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		padding-bottom: 50px
	}

	.TipUsDescription {
		padding-left: 20px;
		padding-right: 20px;
		text-align: center;
		padding-top: 10px;
	}

	.TipUsRadio {
		width: 100%;
		text-align: center;
		padding-top: 10px;
	}

	.TipUsForm {
		width: 100%;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	#Form {
		padding-top: 10px;
		display: flex;
		flex-direction: column;
		padding-left: 0px;
		padding-right: 0px;
	}

	.NameCont {
		display: flex;
		flex-direction: row;
		width:100%;
	}

	.NameLabel,
	.SpaceLabel,
	.ArtistLabel,
	.TypeLabel,
	.LinkLabel,
	.CuratorLabel,
	.ContactLabel,
	.ImageLabel,
	.CaptionLabel,
	.AdmissionLabe,
	.AdmissionCostLabel {
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		float: left;
		display: flex;
		flex-direction: row;
	}

	.NameContent {
		width:65%;
		margin-left: auto;
	}

	.SpaceCont {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: center;
		align-items: center;
		margin-top: 5px;
	}

	.SpaceDropdown {
		margin-left: auto;
		width: 80%;
		border-bottom: 2px solid #019449;
	}

	.react-select-space .react-select-space__control, 
	.react-select-space__control .react-select-space__value-container {
		background-color: transparent;
		border-color: transparent;
		border-style: none;
		padding: 0px;
		min-height: 0px;
	}

	.react-select-space .react-select-space__control {
		width: 100%;
	}

	.react-select-space .react-select-space__control {
		background-color: transparent;
		border-color: transparent;
		border-style: none;
	}

	.ArtistCont {
		display: flex;
		flex-direction: row;
		width:100%;
		margin-top: 5px;
	}

	.ArtistContent {
		width:70%;
		margin-left: auto;
	}

	.TypeCont {
		display: flex;
		flex-direction: row;
		width:100%;
		justify-content: center;
		align-items: center;
		margin-top: 5px;
	}

	.TypeDropdown {
		width:70%;
		margin-left: auto;
		border-bottom: 2px solid #019449
	}
	
	.react-select-time .react-select-time__control, 
	.react-select-time__control .react-select-time__value-container,
	.react-select-type .react-select-type__control, 
	.react-select-type__control .react-select-type__value-container {
		background-color: transparent;
		border-color: transparent;
		border-style: none;
		padding: 0px;
		min-height: 0px;
		width: 90px;
	}

	.ArtistContent {
		width:70%;
		margin-left: auto;
	}

	.DatesExhibit {
		display:flex;
		flex-direction: row;
		width: 100%;
		margin-top: 5px;
		justify-content: center;
		align-items: center;
	}

	.ExhibitStartDateCont {
		display: flex;
		flex-direction: row;
		width: 50%;
	}

	.ExhibitStartDateLabel {
		width: 80%;
	}

	.ExhibitStartCalendar {
		margin-left: auto;
	}

	.DatesExhibit div.react-datepicker__input-container input {
		display: block;
		width: 100% !important;
		text-align: center;
		cursor: pointer;
	}

	.ExhibitEndDateCont {
		display: flex;
		flex-direction: row;
		width: 50%;
	}

	.ExhibitEndDateLabel {
		width: 80%;
	}

	.ExhibitEndCalendar {
		margin-left: auto;
	}

	.DatesEvent {
		display:flex;
		flex-direction: column;
		width: 100%;
		margin-top: 5px;
		justify-content: center;
		align-items: center;
	}

	.EventDateMainCont {
		display: flex;
		flex-direction: row;
		width: 100%;
	}

	.EventDateMainLabel {
		width: 40%;
	}

	.EventDateMainCalendar {
		margin-right:20px;
	}

	.EventDateMainAdd, .EventDateExtraSubtract {
		display: flex;
		border-radius: 200px;
		font-size: 20px;
		height: 20px;
		width: 20px;
		color: #019449;
		justify-content: center;
		align-items: center;
		font-weight: bold;
	}

	.EventDateExtraCont {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-top:5px;
	}

	.EventDateExtra {
		display: flex;
		flex-direction: row;
		width: 100%;
	}

	.EventDateLabel {
		width: 40%;
	}

	.EventDateExtraCalendar {
		margin-right:20px;
	}

	.VernissageStuffCont {
		display:flex;
		flex-direction: column;
		width: 100%;
		justify-content: center;
		margin-top: 5px;
	}

	.VernissageCheckCont {
		display:flex;
		flex-direction: row;
		width: 100%;
		justify-content: center;
		align-items: center;
		margin-top: 5px;
	}

	.VernisageCheckLabel {
		width: 60%;
		display: flex;
		flex-direction: row;
	}

	.react-select-vernissage .react-select-vernissage__control {
		width: 100%;
	}

	.react-select-vernissage .react-select-vernissage__control {
		background-color: transparent;
		border-color: transparent;
		border-style: none;
	}

	.DescriptionCont {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-top: 5px;
	}

	.DescriptionLabel {
		width: 100%;
		text-align: left;
	}

	.DescriptionContent {
		width: 100%;
		resize: none;
	}

	.LinkCont {
		display: flex;
		flex-direction: row;
		width:100%;
		margin-top: 5px;
	}

	.LinkContent,
	.CuratorContent,
	.ContactContent,
	.ImageContent,
	.CaptionContent,
	.AdmissionContent,
	.AdmissionCostContent  {
		width:70%;
		margin-left: auto;
	}

	.CuratorCont {
		display: flex;
		flex-direction: row;
		width:100%;
		margin-top: 5px;
	}

	.ContactCont {
		display: flex;
		flex-direction: row;
		width:100%;
		margin-top: 5px;
	}

	.ImageCont {
		display: flex;
		flex-direction: row;
		width:100%;
		margin-top: 5px;
	}

	.CaptionCont {
		display: flex;
		flex-direction: row;
		width:100%;
		margin-top: 5px;
	}

	.AdmissionCont {
		display: flex;
		flex-direction: column;
		width:100%;
		margin-top: 5px;
	}

	.AdmissionDropDownCont {
		display: flex;
		flex-direction: row;
	}

	.AdimssionDropDown {
		margin-left: auto;
		width: 70%;
		border-bottom: 2px solid #019449;
	}

	.AdmmissionCostCont {
		display: flex;
		flex-direction: row;
		margin-top: 5px;
	}

	.FormButtonCont {
		margin-top: 10px;
		width: 100%;
		text-align: center;
	}

	.FormButtonCont > div > div {
		display: flex;
		place-content: center;
	} 

	.ExhibitSubmit,
	.EventSubmit {
		margin-left: auto;
		margin-right: auto;
		width:-webkit-fit-content;
		width:-moz-fit-content;
		width:fit-content;
		border:1px solid #019449;
		text-align: center;
		padding: 2px;
		background-color: #019449;
		cursor: pointer;
		color: white;
		font-weight: bold;
	}

	/* Terms Page */

	.TermsPageCont {
		width:100%;
		display: flex;
		flex-direction: column;
		justify-content: left;
		height: calc(100vh - 40px);
		overflow-y: auto;
		overflow-x: hidden;
		padding-bottom: 50px
	}

	.TermsContent {
		width: 100%;
		padding-left: 0px;
		padding-right: 0px;
		margin-left: auto;
		margin-right: auto;
		height:-webkit-fit-content;
		height:-moz-fit-content;
		height:fit-content;
		text-align: left;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
	}

	/* Tourplan page */
	.TourPlanCont {
		display:flex;
		height: calc(100vh - 40px);
		width: 100%;
		flex-direction: column;
		padding: 10px;
		overflow-y: auto;
		padding-bottom: 50px
	}

	.TourPlanEntryCont {
		border: 1px solid #019449;
		display: flex;
		border-radius: 2px;
		flex-direction: column;
		margin-bottom: 10px;
		padding: 2px;
	}

	.TourPlanNothing {
		font-size: 18px;
		margin-top: 20px;
	}

	.TourPlanRouteMap {
		font-size: 18px;
		margin-left: auto;
		margin-right: auto;
	}

	/* Maps Attributtes */
	.MapPin {
		width: 15px;
		height: 15px;
		border-radius: 100%;
		text-align: center;
	}

	.PinDetail {
		width: 15px;
		height: 15px;
		border-radius: 100%;
		text-align: center;
		background-color: green
	}

	.MapPinTitle {
		text-align: center;
		justify-content: center;
		display: none;
		font-size: 14px;
		text-decoration:underline;
		padding-top: 15px;
	}

	.PinDetailTitle {
		text-align: center;
		justify-content: center;
		font-size:14px;
		text-decoration:underline;
		padding-top:15px;
		word-wrap: normal;
		color: #019449;
		font-weight: bold;
	}

	.PinDetailTitle > .CalendarEventName {
		word-wrap: normal;
		color: #019449;
		font-weight: bold;
	}

	/* About page */
	.memberPicture {
		width: 150px;
	}

	.memberRole {
		font-size: 10px;
	}
}
.ascii {
	color: black; background: white;
	display:inline-block;
	white-space:pre;
	letter-spacing:0;
	line-height:1.4;
	font-family:'Consolas','BitstreamVeraSansMono','CourierNew',Courier,monospace !important;
	font-size:12px;
	border-width:1px;
	border-style:solid;
	border-color:lightgray;
	height:-webkit-fit-content;
	height:-moz-fit-content;
	height:fit-content;
	position:absolute;
	background-color: white;
	top: 0px;
}

.ascii > pre {
	font-family:'Consolas','BitstreamVeraSansMono','CourierNew',Courier,monospace;
	font-size:10px;
	width: 100vw;
    height: 100vh;
    overflow-y: auto;
}

@media screen and (max-width: 720px) {
	.ascii > pre {
		font-family:'Consolas','BitstreamVeraSansMono','CourierNew',Courier,monospace;
		font-size:2.5px;
		width: 100vw;
		height: 100vh;
		overflow-y: auto;
	}
}

