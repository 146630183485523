.ascii {
	color: black; background: white;
	display:inline-block;
	white-space:pre;
	letter-spacing:0;
	line-height:1.4;
	font-family:'Consolas','BitstreamVeraSansMono','CourierNew',Courier,monospace !important;
	font-size:12px;
	border-width:1px;
	border-style:solid;
	border-color:lightgray;
	height:fit-content;
	position:absolute;
	background-color: white;
	top: 0px;
}

.ascii > pre {
	font-family:'Consolas','BitstreamVeraSansMono','CourierNew',Courier,monospace;
	font-size:10px;
	width: 100vw;
    height: 100vh;
    overflow-y: auto;
}

@media screen and (max-width: 720px) {
	.ascii > pre {
		font-family:'Consolas','BitstreamVeraSansMono','CourierNew',Courier,monospace;
		font-size:2.5px;
		width: 100vw;
		height: 100vh;
		overflow-y: auto;
	}
}
